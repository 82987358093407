<template>
  <v-scale-transition>
    <wide-panel
      :title="title"
      :breadcrumbs="breadcrumbs"
    >
      <v-toolbar
        card
        extended
      >
        <v-container fluid>
          <v-layout row>
            <v-flex xs3>
              <v-menu
                ref="menu"
                v-model="menu"
                lazy
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="290px"
              >
                <v-text-field
                  slot="activator"
                  v-model="monthOf"
                  prepend-icon="event"
                  readonly
                />
                <v-date-picker
                  v-model="monthOf"
                  :max="maxDate"
                  type="month"
                  year-icon="date_range"
                  @change="saveMenu"
                />
              </v-menu>
            </v-flex>
            <v-flex xs6>
              <v-select
                v-model="filter"
                :items="['OneXOX','XOX']"
                label="Filter by"
                clearable
              />
            </v-flex>
            <v-spacer />
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn
                  fab
                  dark
                  small
                  :disabled="!enableExport"
                  :loading="!enableExport"
                  color="green"
                  v-on="on"
                  @click="exportReport"
                >
                  <v-icon dark>
                    mdi-microsoft-excel
                  </v-icon>
                </v-btn>
              </template>
              <span>
                Export Report
              </span>
            </v-tooltip>
          </v-layout>
        </v-container>
      </v-toolbar>
      <v-card>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="filteredItems"
            :loading="loading"
            :rows-per-page-items="[10, 25, 50, 100]"
            :expand="true"
            item-key="lastUpdate"
            class="elevation-1"
            disable-initial-sort
          >
            <template
              slot="items"
              slot-scope="props"
            >
              <tr>
                <td
                  v-if="props.item.dealerId"
                  class="text-xs-left"
                >
                  {{ props.item.dealerId }}
                </td>
                <td
                  v-else
                  class="text-xs-left"
                >
                  -
                </td>
                <td
                  v-if="props.item.name"
                  class="text-xs-left"
                >
                  {{ props.item.name }}
                </td>
                <td
                  v-else
                  class="text-xs-left"
                >
                  -
                </td>
                <td
                  v-if="props.item.rank"
                  class="text-xs-left"
                >
                  {{ props.item.rank }}
                </td>
                <td
                  v-else
                  class="text-xs-center"
                >
                  -
                </td>
                <td
                  v-if="props.item.areaDealer"
                  class="text-xs-left"
                >
                  {{ props.item.areaDealer }}
                </td>
                <td
                  v-else
                  class="text-xs-center"
                >
                  -
                </td>
                <td
                  v-if="props.item.masterDealer"
                  class="text-xs-center"
                >
                  {{ props.item.masterDealer }}
                </td>
                <td
                  v-else
                  class="text-xs-center"
                >
                  -
                </td>
                <td
                  v-if="props.item.reg"
                  class="text-xs-left"
                >
                  {{ props.item.reg }}
                </td>
                <td
                  v-else
                  class="text-xs-center"
                >
                  -
                </td>
                <td
                  v-if="props.item.prepaidAll"
                  class="text-xs-left"
                >
                  {{ props.item.prepaidAll }}
                </td>
                <td
                  v-else
                  class="text-xs-center"
                >
                  -
                </td>
                <td
                  v-if="props.item.prepaid"
                  class="text-xs-left"
                >
                  {{ props.item.prepaid }}
                </td>
                <td
                  v-else
                  class="text-xs-center"
                >
                  -
                </td>
                <td
                  v-if="props.item.WD"
                  class="text-xs-left"
                >
                  {{ props.item.WD }}
                </td>
                <td
                  v-else
                  class="text-xs-left"
                >
                  -
                </td>
                <td
                  v-if="props.item.wawa"
                  class="text-xs-left"
                >
                  {{ props.item.wawa }}
                </td>
                <td
                  v-else
                  class="text-xs-left"
                >
                  -
                </td>
                <td
                  v-if="props.item.unl35"
                  class="text-xs-left"
                >
                  {{ props.item.unl35 }}
                </td>
                <td
                  v-else
                  class="text-xs-left"
                >
                  -
                </td>
                <td
                  v-if="props.item.fcPrepaid"
                  class="text-xs-left"
                >
                  {{ props.item.fcPrepaid }}
                </td>
                <td
                  v-else
                  class="text-xs-left"
                >
                  -
                </td>
                <td
                  v-if="props.item.brPrepaid"
                  class="text-xs-left"
                >
                  {{ props.item.brPrepaid }}
                </td>
                <td
                  v-else
                  class="text-xs-left"
                >
                  -
                </td>
                <td
                  v-if="props.item.blackAll"
                  class="text-xs-left"
                >
                  {{ props.item.blackAll }}
                </td>
                <td
                  v-else
                  class="text-xs-left"
                >
                  -
                </td>
                <td
                  v-if="props.item.black"
                  class="text-xs-left"
                >
                  {{ props.item.black }}
                </td>
                <td
                  v-else
                  class="text-xs-left"
                >
                  -
                </td>
                <td
                  v-if="props.item.ONEFam"
                  class="text-xs-left"
                >
                  {{ props.item.ONEFam }}
                </td>
                <td
                  v-else
                  class="text-xs-left"
                >
                  -
                </td>
                <td
                  v-if="props.item.mysiswa"
                  class="text-xs-left"
                >
                  {{ props.item.mysiswa }}
                </td>
                <td
                  v-else
                  class="text-xs-left"
                >
                  -
                </td>
                <td
                  v-if="props.item.fc"
                  class="text-xs-left"
                >
                  {{ props.item.fc }}
                </td>
                <td
                  v-else
                  class="text-xs-left"
                >
                  -
                </td>
                <td
                  v-if="props.item.brBlack"
                  class="text-xs-left"
                >
                  {{ props.item.brBlack }}
                </td>
                <td
                  v-else
                  class="text-xs-left"
                >
                  -
                </td>
              </tr>
            </template>
            <template slot="no-data">
              <v-alert
                :value="true"
                :type="error ? 'error' : 'info'"
              >
                {{ noDataText }}
              </v-alert>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </wide-panel>
  </v-scale-transition>
</template>

<script>
import { createGetParams, Interval } from '@/rest'
import { DateTime } from 'luxon'
import zipcelx from 'zipcelx'

const pageTitle = 'Dealer Registration List Monthly'

export default {
  components: {
    WidePanel: () => import(/* webpackChunkName: "widePanel" */ '@/components/WidePanel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'DMT Kits', disabled: false, to: '/support/dmt_kits',
        },
        {
          text: pageTitle, disabled: true,
        },
      ],
      activeItem: null,
      alert: false,
      alertType: null,
      alertMessage: null,
      maxDate: '',
      monthOf: null,
      error: null,
      menu: false,
      day: null,
      select: null,
      stat: null,
      headers: [
        {
          align: 'left',
          sortable: true,
          text: 'Dealer Id',
          value: 'dealerId',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Dealer Name',
          value: 'name',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Rank',
          value: 'rank',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Area Dealer',
          value: 'areaDealer',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Master Dealer',
          value: 'masterDealer',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Total',
          value: 'reg',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Prepaid All',
          value: 'prepaidAll',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Organic No WD',
          value: 'prepaid',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Organic With WD',
          value: 'WD',
        },
        {
          align: 'left',
          sortable: true,
          text: 'WAWA',
          value: 'wawa',
        },
        {
          align: 'left',
          sortable: true,
          text: 'UNL35',
          value: 'unl35',
        },
        {
          align: 'left',
          sortable: true,
          text: 'FC Prepaid',
          value: 'fcPrepaid',
        },
        {
          align: 'left',
          sortable: true,
          text: 'BR Prepaid',
          value: 'brPrepaid',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Black All',
          value: 'blackAll',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Organic Black',
          value: 'black',
        },
        {
          align: 'left',
          sortable: true,
          text: 'ONEFamily',
          value: 'ONEFam',
        },
        {
          align: 'left',
          sortable: true,
          text: 'MYSISWA',
          value: 'mysiswa',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Black FC',
          value: 'fc',
        },
        {
          align: 'left',
          sortable: true,
          text: 'BR Black',
          value: 'brBlack',
        },
      ],
      items: [],
      enableExport: true,
      exportedReport: [],
      exportReportHeader:
        [
          {
            value: 'Dealer Id',
            type: 'string',
          },
          {
            value: 'Name',
            type: 'string',
          },
          {
            value: 'Rank',
            type: 'string',
          },
          {
            value: 'Area Dealer',
            type: 'string',
          },
          {
            value: 'Master Dealer',
            type: 'string',
          },
          {
            value: 'Total',
            type: 'string',
          },
          {
            value: 'Prepaid All',
            type: 'string',
          },
          {
            value: 'Organic No WD',
            type: 'string',
          },
          {
            value: 'Organic With WD',
            type: 'string',
          },
          {
            value: 'WAWA',
            type: 'string',
          },
          {
            value: 'UNL35',
            type: 'string',
          },
          {
            value: 'FC Prepaid',
            type: 'string',
          },
          {
            value: 'BR Prepaid',
            type: 'string',
          },
          {
            value: 'Black All',
            type: 'string',
          },
          {
            value: 'Organic Black',
            type: 'string',
          },
          {
            value: 'ONEFamily',
            type: 'string',
          },
          {
            value: 'MYSISWA',
            type: 'string',
          },
          {
            value: 'Black FC',
            type: 'string',
          },
          {
            value: 'BR Black',
            type: 'string',
          },
          {
            value: 'B10',
            type: 'string',
          },
          {
            value: 'B39DB',
            type: 'string',
          },
          {
            value: 'B39PLUS20',
            type: 'string',
          },
          {
            value: 'B39PLUS50',
            type: 'string',
          },
          {
            value: 'B59DB',
            type: 'string',
          },
          {
            value: 'B89DB',
            type: 'string',
          },
          {
            value: 'B150',
            type: 'string',
          },
          {
            value: 'SISWA PLUS',
            type: 'string',
          },
          {
            value: 'TBG50',
            type: 'string',
          },
          {
            value: 'ONEX',
            type: 'string',
          },
        ],
      selects: [
        {
          text: 'All',
          value: 'all',
        },
        {
          text: 'OneXOX',
          value: 'onex',
        },
        {
          text: 'XOX',
          value: 'xox',
        },
      ],
      loading: false,
      snackbar: false,
      snackbarMessage: null,
      search: null,
      searchId: null,
      source: null,
      title: pageTitle,
      pagination: null,
      totalItems: 0,
      filter: 'All',
    }
  },
  computed: {
    noDataText: function () {
      return this.error ? this.error : "There's nothing to display."
    },
    onexox: function () {
      return this.items.filter(item => item.onex)
    },
    xox: function () {
      return this.items.filter(item => !item.onex)
    },
    all: function () {
      return this.items
    },
    filteredItems: function () {
      let items
      if (this.filter === 'OneXOX') items = this.onexox
      else if (this.filter === 'XOX') items = this.xox
      else items = this.all

      for (const item of items) {
        const date = new Date(DateTime.fromSQL(item.date).toMillis())
        item.day = date.getDate()
      }

      return items
    },
  },
  watch: {
    monthOf: function (val) {
      this.getDealerListRecord(val)
    },
    pagination: {
      handler () {
        this.getDealerListRecord(this.monthOf)
      },
      deep: true,
    },
  },
  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.maxDate = this.month = this.monthOf = now.toFormat('yyyy-LL')
    this.select = 'All'
  },
  methods: {
    allowedMonths: function (monthOf) {
      const ym = monthOf.split('-')
      const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone('Asia/Kuala_Lumpur')
      const fromDate = DateTime.local(2015, 1, 1).setZone('Asia/Kuala_Lumpur')
      const now = DateTime.local().setZone('Asia/Kuala_Lumpur')

      return Interval.fromDateTimes(fromDate, now).contains(whatMonth)
    },
    getDealerListRecord (monthOf) {
      this.loading = true
      const params = createGetParams({
        month: monthOf,
      })
      this.$rest.get('getDealerRegistrationList.php', params)
        .then(function (response) {
          this.items = response.data.items
          this.loading = false
        }.bind(this)).catch(error => {
          this.noDataText = error.message
          this.loading = false
        })
    },
    exportReport: function () {
      const config = {
        filename: 'DealerRegistrationList' + '-' + this.monthOf,
        sheet: {
          data: [],
        },
      }
      config.sheet.data.push(this.exportReportHeader)
      this.items.forEach(item => {
        const transaction = [
          {
            value: item.dealerId,
            type: 'string',
          },
          {
            value: item.name,
            type: 'string',
          },
          {
            value: item.rank,
            type: 'string',
          },
          {
            value: item.areaDealer,
            type: 'string',
          },
          {
            value: item.masterDealer,
            type: 'string',
          },
          {
            value: item.reg,
            type: 'string',
          },
          {
            value: item.prepaidAll,
            type: 'string',
          },
          {
            value: item.prepaid,
            type: 'string',
          },
          {
            value: item.WD,
            type: 'string',
          },
          {
            value: item.wawa,
            type: 'string',
          },
          {
            value: item.unl35,
            type: 'string',
          },
          {
            value: item.fcPrepaid,
            type: 'string',
          },
          {
            value: item.brPrepaid,
            type: 'string',
          },
          {
            value: item.blackAll,
            type: 'string',
          },
          {
            value: item.black,
            type: 'string',
          },
          {
            value: item.ONEFam,
            type: 'string',
          },
          {
            value: item.mysiswa,
            type: 'string',
          },
          {
            value: item.fc,
            type: 'string',
          },
          {
            value: item.brBlack,
            type: 'string',
          },
          {
            value: item.b10,
            type: 'string',
          },
          {
            value: item.b39db,
            type: 'string',
          },
          {
            value: item.b39p20,
            type: 'string',
          },
          {
            value: item.b39p50,
            type: 'string',
          },
          {
            value: item.b59db,
            type: 'string',
          },
          {
            value: item.b89db,
            type: 'string',
          },
          {
            value: item.b150,
            type: 'string',
          },
          {
            value: item.siswaPlus,
            type: 'string',
          },
          {
            value: item.tbg50,
            type: 'string',
          },
          {
            value: item.remark,
            type: 'string',
          },
        ]
        config.sheet.data.push(transaction)
      })
      zipcelx(config)
      this.enableExport = true
    },
    cancelRequest: function () {
      if (this.source) {
        this.source.cancel('Forced cancellation.')
        this.source = null
      }
    },
    refreshList () {
      this.getDealerListRecord().then(data => {
        this.items = data.items
      })
    },
    resetList () {
      this.items = []
    },
    saveMenu (date) {
      this.$refs.menu.save(date)
    },
    showError (message) {
      this.alertType = 'error'
      this.alertMessage = message
      this.alert = true
    },
  },
}
</script>
